import React from "react"
import { Alert } from "./model/alert"
import { Row, Col, Card, CardBody, CardHeader, CardText } from "reactstrap"

type Props = {
  alert: Alert
}

export function AlertItem({ alert }: Props) {

  const getAlertDate = () => {
    const alertDate = new Date(alert.timestamp)
    const hours = (alertDate.getHours() < 10 ? "0" : "") + alertDate.getHours();
    const minutes = (alertDate.getMinutes() < 10 ? "0" : "") + alertDate.getMinutes();
    return alertDate.toDateString() + " " + hours + ":" + minutes;
  };

  const alertColour = alert.viewed ? "#c0c0c0" : "#00b7eb";

  return (
    <Row>
      <Col className="col-12">
        <Card
          className="my-2"
          style={{
            width: "100%",
            border: "0px"
          }}
        >
          <CardHeader className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-3 col-xxl-2" style={{borderBottom: "0px", backgroundColor: alertColour}}>{getAlertDate()}</CardHeader>
          <CardBody style={{border: "4px", borderColor: alertColour, borderStyle: "solid", borderRadius: "0em 0.5em 0.5em 0.5em"}}>
            <CardText color="primary">{alert.text}</CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
