import React, { useState } from "react";
import { Alert } from "./model/alert";
import { AlertState, Tag } from "./model/tag";
import { AlertItem } from "./AlertItem"
import { Button, Col, Container, Row } from "reactstrap";
import { Confirm } from "./Confirm";
import Config from './Config';
import { KofiButton } from "react-kofi-button";

type Props = {
  alerts: Alert[] | [],
  tag: Tag,
  loading: boolean,
  userId: string,
  refresh: () => void
}

export function AlertList({tag, alerts, loading, userId, refresh}: Props) {
  const [showClearConfirm, setClearConfirm] = useState(false);
  const [viewTag] = useState(tag);

  const doClear = function() {
    alerts.forEach(alert => {
      fetch(`${Config('api_server')}alert/${tag.id}/${alert.id}`, {
        method: "DELETE"
      })
      .then((resp) => {
        console.log(resp.body);
        setClearConfirm(false);
        refresh();
      })
      .catch((err) => {
        console.log(err.message);
        setClearConfirm(false);
      });
    });
    fetch(`${Config('api_server')}tag/${userId}/${tag.id}`, {
      method: "PUT",
      body: JSON.stringify({
        alert: AlertState.NONE
      })
    })
    .then((data) => {
      console.log(data);
    });
  }

  const doEnable = function() {
    fetch(`${Config('api_server')}tag/${userId}/${tag.id}`, {
      method: "PUT",
      body: JSON.stringify({
        "disabled": false
      })
    })
    .then(() => {
      viewTag.disabled = false;
      refresh();
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  return (
    <Container>
    <Confirm show={showClearConfirm} doClose={() => { setClearConfirm(false) }} title="Clear Alerts" message={
        <p>Clear all alerts for tag: &quot;{tag.description}&quot;?</p>
      } actionVerb="Clear" actionFunc={() => doClear()}/>
      <Row>
        <Col className="col-12" style={{ display: 'flex'}}>
          <h4 className="my-3"  style={{ flex: '1'}}>Alerts for tag: &quot;{tag?.description}&quot;</h4>
          {!loading && alerts.length > 0 && (
            <div className="my-3" style={{alignSelf: "flex-end"}}>
              <Button
                color="primary"
                onClick={()=>{
                  setClearConfirm(true);
                }}
              >
                Clear Alerts
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
      {loading && <img className="position-absolute middle start-50" width={64} height={64} src={require('./assets/tracker-spinner.gif')} alt="loading..." />}
      {!loading && viewTag.disabled && (
        <>
          <p style={{padding: "12px", border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#ffcc00", backgroundColor: "#fff0b3"}}>
          <p>This tag is currently disabled, this will prevent any finders from alerting you.</p>
          <div style={{display: "flex", justifyContent: "end"}}>
            <Button
              color="primary"
              onClick={()=>{
                doEnable();
              }}
            >
              Enable Tag
            </Button>
          </div>
          </p>
        </>
      )}
      {!loading && alerts.map(function (alert) {
        return <AlertItem alert={alert} key={alert.id}/>
      })}
      {!loading && alerts.length == 0 && (
        <>
          <p style={{padding: "12px", border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC"}}>
            <p>There are no alerts for this tag.</p>
          </p>
        </>
      )}
        </Col>
      </Row>
      <Row>
        <Col className="col-12" style={{width: '-webkit-fill-available', position: "relative"}}>
          <div style={{position: "absolute", padding: "12px", right: "0px"}}>
            <KofiButton title="If this App helps you get re-united with your belongs, please consider supporting us!" username="findertags" label="Support Us!" backgroundColor="#00b7eb"/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
