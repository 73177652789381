import React from "react"

import {
  Modal,
  ModalBody,
  Button,
  ModalFooter,
} from 'reactstrap';

type Props = {
  show: boolean,
  doClose: () => void
}

export function Donate({ show, doClose }: Props) {
  const hide = () => { doClose() };

  function Iframe() {
    return (<div dangerouslySetInnerHTML={ {__html: '<iframe id="kofiframe" src="https://ko-fi.com/findertags/?hidefeed=true&widget=true&embed=true&preview=true" style={{border: "none", width: "300px", padding: "4px", background: "#f9f9f9;"}} height="590px" title="Finder Tags"></iframe>'}} />);
  }
  
  return (
      <Modal isOpen={show}  style={{width: "340px", height: "620px"}}>
        <ModalBody>
          <Iframe/>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={hide}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
  );
}
