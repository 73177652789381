import React, { useState } from "react"
import { AlertState, Tag } from "./model/tag"
import { Row, Col, Button } from "reactstrap"
import { ReactComponent as EditIcon } from "./assets/edit.svg"
import { ReactComponent as AlertNewIcon } from "./assets/alert-new.svg"
import { ReactComponent as AlertNoneIcon } from "./assets/alert-none.svg"
import { ReactComponent as AlertReadIcon } from "./assets/alert-read.svg"
import { ReactComponent as EnabledIcon } from "./assets/enabled.svg"
import { ReactComponent as DisabledIcon } from "./assets/disabled.svg"
import { ReactComponent as QRCodeIcon } from "./assets/qr-code.svg"
import { ReactComponent as DeleteIcon } from "./assets/delete.svg"
// import short from 'short-uuid';

import { Tooltip } from "react-tooltip"
import { Confirm } from "./Confirm"
import { EditTag } from "./EditTag"
import { ShowQRCode } from "./ShowQRCode"
import Config from './Config';

// const translator = short();

type Props = {
  tag: Tag,
  evenRow: boolean,
  userId: string,
  refresh: () => void
}

export function TagItem({ tag, evenRow, userId, refresh }: Props) {
  const [showEditDialog, setEditDialog] = useState(false);
  const [showQRCodeDialog, setQRCodeDialog] = useState(false);
  const [showEnableConfirm, setEnableConfirm] = useState(false);
  const [showDisableConfirm, setDisableConfirm] = useState(false);
  const [showDeleteConfirm, setDeleteConfirm] = useState(false);
  // const [owner] = useState(translator.fromUUID(userId).substring(0,5));
  const [owner] = useState(userId);

  const doDisable = function(disabled: boolean) {
    fetch(`${Config('api_server')}tag/${owner}/${tag.id}`, {
      method: "PUT",
      body: JSON.stringify({
        "disabled": disabled
      })
    })
    .then(() => {
      disabled ? setDisableConfirm(false) : setEnableConfirm(false);
      refresh();
    })
    .catch((err) => {
      disabled ? setDisableConfirm(false) : setEnableConfirm(false);
      console.log(err.message);
    });
  }

  const doDelete = function() {
    fetch(`${Config('api_server')}tag/${owner}/${tag.id}`, {
      method: "DELETE"
    })
    .then((res) => res.json())
    .then(() => {
      setDeleteConfirm(false);
      refresh();
    })
    .catch((err) => {
      setDeleteConfirm(false);
      console.log(err.message);
    });
  }

  return (
    <Row>
      <EditTag show={showEditDialog} doClose={() => { setEditDialog(false) }} tagId={tag.id} userId={owner} origDescription={tag.description} doRefresh={() => refresh()}/>
      <ShowQRCode show={showQRCodeDialog} doClose={() => { setQRCodeDialog(false) }} tagId={tag.id} description={tag.description} owner={owner}/>
      <Confirm show={showEnableConfirm} doClose={() => { setEnableConfirm(false) }} title="Enable Tag" message={
        <>
          <p>Finders will now be able to scan tag: &quot;{tag.description}&quot;.</p>
          <p>You will receive alerts for any future scans.</p>
        </>
        } actionVerb="Enable" actionFunc={() => doDisable(false)}/>
      <Confirm show={showDisableConfirm} doClose={() => { setDisableConfirm(false) }} title="Disable Tag" message={
        <>
          <p>It will no longer be possible for anyone to scan tag: &quot;{tag.description}&quot;.</p>
          <p>Therefore you will not receive any alerts until it is re-enabled.</p>
        </>
        } actionVerb="Disable" actionFunc={() => doDisable(true)}/>
      <Confirm show={showDeleteConfirm} doClose={() => { setDeleteConfirm(false) }} title="Delete Tag" message={
          <p>Delete tag: &quot;{tag.description}&quot;. It will no longer be possible to scan this tag, you will not receive any alerts.</p>
        } actionVerb="Delete" actionFunc={() => doDelete()}/>
      <Col className={`py-1 col-6 col-sm-7 col-md-9 col-lg-9 col-xl-10 ${evenRow ? "bg-light" : ""}`}>{tag.description}</Col>
      <Col className={`col-6 col-sm-5 col-md-3 col-lg-3 col-xl-2 ${evenRow ? "bg-light" : ""}`}>
        <Button
          style={{ border: "0px", padding: "6px" }}
          outline={true}
          data-tooltip-id="editTagDescription"
          data-tooltip-content="Edit Tracker Tag Description"
          data-tooltip-place="bottom"
          data-tooltip-delay-show={1000}
          onClick={()=>{
            setEditDialog(true);
          }}
        >
          <EditIcon />
        </Button>
        <Tooltip id="editTagDescription" opacity={0.4} />
        <a href={`/alert/${owner}/${tag.id}`}>
        {tag.alert == AlertState.NONE && (
          <>
            <Button
              style={{ border: "0px", padding: "6px" }}
              data-tooltip-id="noAlertsForTag"
              data-tooltip-content="No Alerts"
              data-tooltip-place="bottom"
              data-tooltip-delay-show={1000}
              outline={true}
            >
              <AlertNoneIcon />
            </Button>
            <Tooltip id="noAlertsForTag" opacity={0.4} />
          </>
        )}
        {tag.alert == AlertState.NEW && (
          <>
            <Button
              style={{ border: "0px", padding: "6px" }}
              data-tooltip-id="newAlertsForTag"
              data-tooltip-content="There are new alerts for this Tag"
              data-tooltip-place="bottom"
              data-tooltip-delay-show={1000}
              outline={true}
            >
              <AlertNewIcon />
            </Button>
            <Tooltip id="newAlertsForTag" opacity={0.4} />
          </>
        )}
        {tag.alert == AlertState.READ && (
          <>
            <Button
              style={{ border: "0px", padding: "6px" }}
              data-tooltip-id="noNewAlertsForTag"
              data-tooltip-content="No new alerts for this Tag"
              data-tooltip-place="bottom"
              data-tooltip-delay-show={1000}
              outline={true}
            >
              <AlertReadIcon />
            </Button>
            <Tooltip id="noNewAlertsForTag" opacity={0.4} />
          </>
        )}
        </a>
        {tag.disabled === false && (
          <>
            <Button
              style={{ border: "0px", padding: "6px" }}
              data-tooltip-id="enabledTag"
              data-tooltip-content="Disable Tracker Tag"
              data-tooltip-place="bottom"
              data-tooltip-delay-show={1000}
              outline={true}
              onClick={()=>{
                setDisableConfirm(true);
              }}
            >
              <EnabledIcon/>
            </Button>
            <Tooltip id="enabledTag" opacity={0.4} />
          </>
        )}
        {tag.disabled && (
          <>
            <Button
              style={{ border: "0px", padding: "6px" }}
              data-tooltip-id="disableTag"
              data-tooltip-content="Enable Tracker Tag"
              data-tooltip-place="bottom"
              data-tooltip-delay-show={1000}
              outline={true}
              onClick={()=>{
                setEnableConfirm(true);
              }}
            >
              <DisabledIcon/>
            </Button>
            <Tooltip id="disableTag" opacity={0.4} />
          </>
        )}
        <Button
          style={{ border: "0px", padding: "6px" }}
          data-tooltip-id="viewQRCode"
          data-tooltip-content="Show Tracker Tag QR-Code"
          data-tooltip-place="bottom"
          data-tooltip-delay-show={1000}
          outline={true}
          onClick={()=>{
            setQRCodeDialog(true);
          }}
        >
          <QRCodeIcon/>
        </Button>
        <Tooltip id="viewQRCode" opacity={0.4} />
        <Button
          style={{ border: "0px", padding: "6px" }}
          data-tooltip-id="deleteTag"
          data-tooltip-content="Delete Tracker Tag"
          data-tooltip-place="bottom"
          data-tooltip-delay-show={1000}
          outline={true}
          onClick={()=>{
            setDeleteConfirm(true);
          }}
        >
          <DeleteIcon/>
        </Button>
        <Tooltip id="deleteTag" opacity={0.4} />
      </Col>
    </Row>
  )
}
