/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Col,
  Row,
  NavbarText,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { Donate } from "./Donate";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import trackerLogo from "./assets/tracker-logo.png";

type Props = {
  page: string,
  signOut: (() => void) | undefined,
}

export function NavBar({ page, signOut }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDonate, setShowDonate] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const navigate = (loc: string) => {
    location.href = loc;
  }

  return (
    <>
    <Navbar className="content" container={true} color="dark" dark={true}>
      <Donate
        show={showDonate}
        doClose={() => {
          setShowDonate(false)
        }}
      />
      <Row style={{width: '-webkit-fill-available'}}>
        <Col style={{width: "min-content", flex: "none"}}>
          <NavbarBrand href="/tags">
            <img className="my-1" src={trackerLogo} width={64} height={64} alt="Finder Tag Logo"/>
          </NavbarBrand>
        </Col>
        <Col style={{flex: "1"}}>
          <NavbarText>
            <h3 style={{color: "white"}}>Finder Tag</h3>
            <p>Allow the finder of your lost item to get in touch.</p>
          </NavbarText>
        </Col>
        <Col style={{width: "min-content", flex: "none"}}>
          <NavbarText>
            <div style={{ overflow: 'hidden', padding: '8px', width: "min-content", justifyContent: "end"}}>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} color="primary">
                <DropdownToggle color="dark">
                  <MenuIcon />
                </DropdownToggle>
                <DropdownMenu container="body" dark>
                  <DropdownItem onClick={() => setShowDonate(true)}>
                    Donate
                  </DropdownItem>
                  <DropdownItem hidden={page === "faq"} onClick={() => navigate("/faq")}>
                    FAQ
                  </DropdownItem>
                  <DropdownItem hidden={page === "tags"} onClick={() => navigate("/tags")}>
                    My Tags
                  </DropdownItem>
                  <DropdownItem hidden={true} onClick={function noRefCheck(){}}>
                    Help
                  </DropdownItem>
                  <DropdownItem hidden={true} onClick={function noRefCheck(){}}>
                    Contact Us
                  </DropdownItem>
                  <DropdownItem hidden={signOut === undefined} divider />
                  <DropdownItem hidden={signOut === undefined} onClick={signOut}>
                    Sign Out
                  </DropdownItem>
                  <DropdownItem hidden={page !== "home"} onClick={() => navigate("/tags")}>
                    Sign In
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </NavbarText>
        </Col>
      </Row>
    </Navbar>
  </>
  )
}
