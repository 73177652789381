import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from "reactstrap"
import Config from './Config';

type Props = {
  show: boolean,
  tagId: string,
  origDescription: string,
  userId: string,
  doClose: () => void,
  doRefresh: () => void
}

export function EditTag({ show, tagId, origDescription, userId, doClose, doRefresh }: Props) {
  const [description, setDescription] = useState(origDescription)

  const hide = () => {
    doClose()
  }

  function onClickSave() {
    fetch(`${Config('api_server')}tag/${userId}/${tagId}`, {
      method: "PUT",
      body: JSON.stringify({
        description: description
      })
    })
    .then(() => {
      doClose()
      doRefresh()
    })
    .catch((err) => {
      doClose()
      console.log(err.message)
    })
  }

  const changeDescription = (e: { target: { value: string } }) => {
    setDescription(e.target.value)
  }

  return (
    <Modal isOpen={show} keyboard={true} tabIndex={-1}>
      <ModalHeader>Edit Tag</ModalHeader>
      <ModalBody>
        <Form inline="true" onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label for="description">Description</Label>{" "}
            <Input
              type="text"
              name="description"
              id="description"
              value={description}
              onChange={changeDescription}
            ></Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClickSave}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={hide}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
