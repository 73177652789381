import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  List,
  Container
} from "reactstrap"
import { useLoaderData } from "react-router-dom"
import Captcha from "react-google-recaptcha";
import Config from './Config';
import { NavBar } from "./NavBar";

type Params = {
  params: {
    owner: string,
    nonce: number,
    tagId: string
  }
}

type Validation = {
  nonce: number,
  captcha: boolean
}

export function tagIdLoader(params: unknown) {
  const alertParams = params as Params;
  console.log({ap: alertParams.params});
  return alertParams;
}

export default function NewAlert() {
  const [alertText, setAlertText] = useState("");
  const [loading, setLoading] = useState(true);
  const [sent, setSent] = useState(false);
  const [valid, setValid] = useState(false);
  const [validation, setValidation] = useState<Validation>({ nonce: 0, captcha: false});
  const [captchaEnabled, setCaptchaEnabled] = useState(false);
  const [captchaDone, setCaptchaDone] = useState("");
  const params = useLoaderData() as Params;
  const { tagId, owner } = params.params;

  const captchaRef = useRef<Captcha>(null);

  async function onClickSend() {
    fetch(`${Config('api_server')}tag/alert/${owner}`, {
      method: "POST",
      body: JSON.stringify({
        tagId: tagId,
        nonce: validation.nonce,
        recaptcha: captchaDone,
        text: alertText
      })
    })
    .then((res) => res.json())
    .then(() => {
      setSent(true);
    })
    .catch((err) => {
      console.log(err.message)
    })
  }

  const validate = function (): void {
    setLoading(true);
    fetch(`${Config('api_server')}validate/${owner}/${tagId}`)
    .then((res) => res.json())
    .then((data) => {
      setValidation(data);
      setCaptchaEnabled(data.captcha);
      setValid(true);
    })
    .catch(() => {
      setValid(false);
    })
    .finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    validate()
  }, [])
  
  const changeAlertText = (e: { target: { value: string } }) => {
    setAlertText(e.target.value)
  }

  function changeCaptchaDone(value: string | null) {
    console.log(value);
    if (value !== null) {
      setCaptchaDone(value);
    }
  }

  return (
    <div className="app-container">
      <div className="app-header">
        <NavBar page="alert" signOut={undefined}/>
      </div>
      <div className="app-content">
        <Container className="my-3">
          {!sent && !loading && valid && (
            <>
              <h4 className="my-3">Item Location Details</h4>
              <p style={{border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC", padding: "12px"}}>
                Please use this form to send the owner a message letting them know that you have found their item and how
                they can retrieve it. Think carefully about including personal information on this form. Consider including
                the following information:
                <List>
                  <p>&#9734; Where the item has been left (for example at a lost property office).</p>
                  <p>&#9734; How the owner can contact the custodian (email address or telephone number).</p>
                  <p>&#9734; Any information that might be useful to the owner.</p>
                </List>
              </p>
              <Form inline="true" onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                  <Label style={{borderRadius: "0.5em 0.5em 0em 0em", borderBottom: "0px", backgroundColor: "#00b7eb", padding: "4px 16px", margin: "0px"}} for="alertText">Message:</Label>
                  <Input style={{height: "200px", padding: "12px", border: "4px", borderRadius: "0em 0.5em 0.5em 0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC"}} type="textarea" name="alertText" id="alertTexrt" onChange={changeAlertText}></Input>
                </FormGroup>
              </Form>
              <div style={{display: "flex", justifyContent: "end"}}>
                {/* Only enable CAPTCHA script if expected */}
                {captchaEnabled && !captchaDone && (
                  <Captcha
                    ref={captchaRef}
                    size="normal"
                    onChange={changeCaptchaDone}
                    sitekey={Config('recaptcha_public_key')}
                  />
                )}
                {(!captchaEnabled || !!captchaDone) && (
                  <Button color="primary" onClick={onClickSend}>Send</Button>
                )}
              </div>
            </>
          )}
          {sent && !loading && valid && (
            <>
              <h1>Thank you!</h1>
              <p style={{border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC", padding: "12px"}}>
              <p>
                Many thanks for using this service. We are sure that the owner of the located item will be delighted to
                receive your message.
              </p>
              <p>
                If you would like to create you own free Finder Tags, visit our  <a href="/">home page</a> for details.
              </p>
              </p>
            </>
          )}
          {!loading && !valid && (
            <>
              <h1>Tag Not found</h1>
              <p style={{border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC", padding: "12px"}}>
                You have found an invalid, disabled, or cancelled tag. If you believe that the item you have found would
                still be of value to its owner, please check back in a day or two as they may re-activate the tag.
              </p>
            </>
          )}
        </Container>
      </div>
    </div>
  )
}
