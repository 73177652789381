import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import QRCode from "react-qr-code";
import Config from './Config';

type Props = {
  show: boolean,
  tagId: string,
  description: string,
  owner: string,
  doClose: () => void
}

export function ShowQRCode({ show, tagId, description, owner, doClose }: Props) {
  const hide = () => {
    doClose()
  }

  return (
    <Modal isOpen={show} keyboard={true} tabIndex={-1}>
      <ModalHeader>QRCode</ModalHeader>
      <ModalBody>
        <p>QRCode for tag: &quot;{description}&quot;</p>
        <p>Print and attach this image to the item to be tracked.</p>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "50%" }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${Config('app_url')}f/${owner}/${tagId}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={hide}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}
