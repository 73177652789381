import React, { ReactNode } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

type Props = {
  show: boolean,
  doClose: () => void,
  title: string,
  message: ReactNode,
  actionVerb: string,
  actionFunc: () => void
}

export function Confirm({ show, doClose, title, message, actionVerb, actionFunc }: Props) {

  const hide = () => { doClose() };

  return (
    <div>
      <Modal isOpen={show}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {message}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={actionFunc}>
            {actionVerb}
          </Button>{' '}
          <Button color="secondary" onClick={hide}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
