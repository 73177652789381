/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react"
import { AuthEventData } from '@aws-amplify/ui';

import '@aws-amplify/ui-react/styles.css';
import { NavBar } from "./NavBar"
import { Tag } from "./model/tag"
import { TagsList } from "./TagsList"
import { AuthUser } from "aws-amplify/auth";
import Config from './Config';
import { Button, Col, Container, Input, InputGroup, InputGroupText, Row, } from "reactstrap";
import { CreateTag } from "./CreateTag"
import short from 'short-uuid';

const translator = short();

type Props = {
  user: AuthUser,
  signOut: ((data?: AuthEventData | undefined) => void) | undefined
}

function Main({ user, signOut }: Props) {
  const [userId] = useState(translator.fromUUID(user.userId).substring(0,5));
  const [tags, setTags] = useState<Tag[] | []>([])
  const [loading, setLoading] = useState(true);
  const [tagFilter, setTagFilter] = useState("");
  const [filteredTags, setFilteredTags] = useState<Tag[] | []>([]);
  const [showCreate, setShowCreate] = useState(false);
  const [filter, setFilter] = useState("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    console.log("New Filter", newValue);
    setFilter(newValue);
    setTagFilter(newValue);
  }

  const sorter = function(s1: Tag, s2: Tag): number {
    if (s1.alert != s2.alert) {
      return s1.alert < s2.alert ? -1 : 1
    } else {
      return s1.description.toLocaleLowerCase().localeCompare(s2.description.toLocaleLowerCase());
    }
  }

  const filterTags = function(): void {
    if (tagFilter.length === 0) {
      setFilteredTags(tags.sort(sorter));
    } else {
      // For some reason Chrome on Android doesn't like to 'toLowerCase' function.
      setFilteredTags(tags.filter(tag => tag.description.includes(tagFilter) || tag.description.toLowerCase().includes(tagFilter)).sort(sorter));
    }
  }

  const refresh = function (): void {
    setLoading(true);
    fetch(`${Config('api_server')}tag/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log({data});
        setTags(data);
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    filterTags();
  }, [tags, tagFilter]);

  return (
    <div className="app-container">
      <div className="app-header">
        <NavBar page="tags" signOut={signOut}/>
      </div>
      <CreateTag
        show={showCreate}
        doClose={() => {
          setShowCreate(false)
        }}
        userId={userId}
        doRefresh={() => refresh()}
      />
      <div className="app-content">
        <Container className="my-3">
          <Row style={{width: '-webkit-fill-available'}}>
            <Col>&nbsp;</Col>
            <Col className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4" style={{display: "flex", verticalAlign: "baseline", justifyContent: "end"}}>
              <InputGroup>
                <Button color="primary" className="sm" active onClick={() => setShowCreate(true)}>Create New Tag</Button>
                <InputGroupText className="sm">Filter List:</InputGroupText>
                <Input bsSize="sm" id="filter" name="filter" type="text" value={filter} onChange={onChange}/>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Container className="my-3" style={{}}>
        {loading && <img className="position-absolute middle start-50" width={64} height={64} src={require('./assets/tracker-spinner.gif')} alt="loading..." />}
        {!loading && filteredTags.length > 0 && <TagsList tags={filteredTags} userId={userId} refresh={() => refresh()} />}
        {!loading && tags.length === 0 && (
          <>
            <p style={{padding: "12px"}}></p>
            <p style={{border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC", padding: "12px"}}>
            You don&apos;t have any Tags yet. Create your first Tag using the &quot;Create New Tag&quot; button above.
            </p>
          </>
        )}
        {!loading && tags.length > 0 && filteredTags.length === 0 && (
          <>
            <p style={{padding: "12px"}}></p>
            <p style={{border: "4px", borderRadius: "0.5em", borderStyle: "solid", borderColor: "#00b7eb", backgroundColor: "#B3E5FC", padding: "12px"}}>
            There aren&apos;t any Tags that match your filter criteria - Containing &quot;{tagFilter}&quot;.
            </p>
          </>
        )}
        </Container>
      </div>
    </div>
  )
}

export default Main
