import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Alert } from "./model/alert";
import { AlertState, Tag } from "./model/tag";
import { AlertList } from "./AlertList";
import Config from "./Config";
import { NavBar } from "./NavBar";

type Params = {
  params: {
    tagId: string,
    userId: string  
  }
}

type Data = {
  tag: Tag,
  userId: string
}

export async function tagLoader(params: unknown): Promise<Data> {
  const alertParams = params as Params;
  const resp = await fetch(`${Config('api_server')}tag/${alertParams.params.userId}/${alertParams.params.tagId}`);
  const tag = await resp.json();
  return { tag, userId: alertParams.params.userId };
}

function ViewAlert() {
  const [alerts, setAlerts] = useState<Alert[] | []>([])
  const [loading, setLoading] = useState(true);
  const data = useLoaderData() as Data;
  const { tag, userId } = data;

  const sorter = function(s1: Alert, s2: Alert): number {
    if (s1.timestamp < s2.timestamp) {
      return 1;
    } else if (s1.timestamp > s2.timestamp) {
      return -1;
    } else {
      return 0;
    }
  }

  const refresh = function() {
    setLoading(true);
    fetch(`${Config('api_server')}alert/${tag.id}`)
      .then((res) => res.json())
      .then((data) => {
        const alerts = data as Alert[];
        setAlerts(alerts.sort(sorter));
        alerts.forEach(alert => {
          if (!alert.viewed) {
            fetch(`${Config('api_server')}alert/${tag.id}/${alert.id}`, {
              method: "PUT",
              body: JSON.stringify({
                "viewed": true
              })
            })
            .then((resp) => {
              console.log(resp.body);
            })
            .catch((err) => {
              console.log(err.message);
            })
          }
        });
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    refresh();
  }, [])

  useEffect(() => {
    fetch(`${Config('api_server')}tag/${userId}/${tag.id}`, {
      method: "PUT",
      body: JSON.stringify({
        alert: alerts.length ? AlertState.READ : AlertState.NONE
      })
    })
    .then((data) => {
      console.log(data);
    })
  }, [alerts])

  return (
    <div className="app-container">
      <div className="app-header">
        <NavBar page="alerts" signOut={undefined}/>
      </div>
      <div className="app-content">
        <AlertList alerts={alerts} tag={tag} userId={userId} refresh={refresh} loading={loading}/>
      </div>
    </div>
  )
}

export default ViewAlert
