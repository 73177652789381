type Config = {
  api_server: string,
  app_url: string,
  recaptcha_public_key: string
}

const production: Config = {
  api_server: "https://ys1uw17ua0.execute-api.eu-west-2.amazonaws.com/Prod/",
  app_url: "https://findtags.org/",
  recaptcha_public_key: "6LewhJUpAAAAAOaljJA5UEVz8GjyJpM7kcln6CNP"
}

const development: Config = {
  api_server: "https://ys1uw17ua0.execute-api.eu-west-2.amazonaws.com/Stage/",
  app_url: "http://192.168.86.126:3000/",
  recaptcha_public_key: "6LewhJUpAAAAAOaljJA5UEVz8GjyJpM7kcln6CNP"
}

export default function Config(prop: keyof Config): string {
  if (process.env.NODE_ENV === 'production') {
    return production[prop];
  } else {
    return development[prop];
  }
}
