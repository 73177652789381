/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container
} from "reactstrap"

import { NavBar } from "./NavBar";

export default function Faq() {
  const [open, setOpen] = useState('1');

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  return (
    <div className="app-container">
      <div className="app-header">
        <NavBar page="faq" signOut={undefined}/>
      </div>
      <div className="app-content">
      <Container className="my-3" style={{}}>
        <Accordion open={open} toggle={toggle} style={{border: "0px"}}>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader className="bg-light" targetId="1">How much does it cost?</AccordionHeader>
            <AccordionBody accordionId="1">
              <strong>This App is free for personal use.</strong>&nbsp;
              This App is funded by donations. Please make a donation through my <a href="https://ko-fi.com/findertags" target="_blank" rel="noreferrer">Ko-Fi</a> if you can, it will be greatly appreciated and help to cover the costs of
              hosting.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="2">Will the &apos;Finder&apos; of my item need an account?</AccordionHeader>
            <AccordionBody accordionId="2">
              <strong>No, an account is not required to submit a finders report.</strong>&nbsp;
              When the Finder sees the QR-Code on your item they can scan it and send you a message. Our intention is to make it
              as easy as possible for Finders to alert the owner of the item&apos;s whereabouts.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader className="bg-light" targetId="3">Are my personal details safe?</AccordionHeader>
            <AccordionBody accordionId="3">
              <strong>Yes, this service is completely anonymous for the item owner and Finder.</strong>&nbsp;
              The only information this App has about you is your email address and item description.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="4">Can I message the Finder of my item?</AccordionHeader>
            <AccordionBody accordionId="4">
              <strong>No, this App does not allow two way messaging.</strong>&nbsp;
              If the Finder of your item willingly shares their contact details (email address or phone number) that is up to them.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="5">How will I be notified if my item is found?</AccordionHeader>
            <AccordionBody accordionId="5">
              <strong>Please login to this App and check for notifications.</strong>&nbsp;
              At the current time the only means of viewing notifications is by logging into the App and checking. If there is a notification for your
              item the notification bell will be shown in yellow. Items with unread notifications are placed at the top of your list of tags.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="6">How many tags can I create?</AccordionHeader>
            <AccordionBody accordionId="6">
              <strong>As many as you like.</strong>&nbsp;
              There is no limit to the number of tags that you may create with this App.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="7">Can I use the same Tag on more than one item?</AccordionHeader>
            <AccordionBody accordionId="7">
              <strong>Yes, there is not reason why you can&apos;t do this.</strong>&nbsp;
              This is entirely your choice. It will mean that you won&apos;t be able to distinguish the specific item to which the Alert relates. However,
              hopefully you will realise which item is not in your possession.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="8">Will I get spammed with Alerts for my item?</AccordionHeader>
            <AccordionBody accordionId="8">
              <strong>No, this site has protections to prevent that.</strong>&nbsp;
              It would be wise to attach the Tag to your item in a location that is discrete location. This will guard against being scanned by passers-by. However,
              the location of the Tag should be obvious enough for the Finder to see. Repeated scans will result in the need to complete a &apos;Captcha&apos; challenge. The App also
              has a built in safe-guard against any automated Alert submission, such as by a Bot.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="9">What if the Tag gets damaged?</AccordionHeader>
            <AccordionBody accordionId="9">
              <strong>Just create another one.</strong>&nbsp;
              You may either just re-use your existing Tag, or you may delete it and create an entirely new Tag for your item. It&apos;s up to you.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="bg-light" style={{border: "0px"}}>
            <AccordionHeader targetId="10">What if I want to pause notifications for a Tag?</AccordionHeader>
            <AccordionBody accordionId="10">
              <strong>Tags can be disabled.</strong>&nbsp;
              When disabled anyone scanning the Tag will get a message saying that it either does not exist, or is inactive. This may be useful if you know where
              the item is and want to prevent repeated scans. Or if the Tag is in a prominent place that will get scanned unnecessarily. Be aware, however, that
              should you misplace the item you will need to re-enable the Tag before anyone can submit a Finder&apos;s Alert.
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </Container>
      </div>
    </div>
  )
}
