import React from "react";
import { Tag } from "./model/tag";
import { TagItem } from "./TagItem"

type Props = {
  tags: Tag[] | [],
  userId: string,
  refresh: () => void
}

export function TagsList({tags, userId, refresh}: Props) {
  return (
    <>
      {tags.length > 0 && tags.map(function (tag, index) {
        return <TagItem tag={tag} evenRow={index%2==0} key={tag.id} userId={userId} refresh={refresh}/>
      })}
    </>
  )
}
