import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from 'reactstrap';
import Config from './Config';

type Props = {
  show: boolean,
  userId: string,
  doClose: () => void,
  doRefresh: () => void
}

export function CreateTag({ show, userId, doClose, doRefresh }: Props) {
  const [description, setDescription] = useState("");

  const hide = () => { doClose() };

  function onClickCreate() {
    fetch(`${Config('api_server')}tag/${userId}`, {
      method: "POST",
      body: JSON.stringify({
        "description": description
      })
    })
    .then((res) => res.json())
    .then(() => {
      doClose();
      doRefresh();
    })
    .catch((err) => {
      doClose()
      console.log(err.message);
    });
  }

  const changeDescription = (e: { target: { value: string; }; }) => {
    setDescription(e.target.value);
  };
 
  return (
      <Modal isOpen={show}>
        <ModalHeader>Create Tag</ModalHeader>
        <ModalBody>
        <Form inline="true" onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label for="description">Description</Label>{' '}
            <Input
              type="text"
              name="description"
              id="description"
              onChange={changeDescription}
            >
            </Input>
          </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClickCreate}>
            Create
          </Button>{' '}
          <Button color="secondary" onClick={hide}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
  );
}
