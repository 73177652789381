/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react"

import '@aws-amplify/ui-react/styles.css';
import { NavBar } from "./NavBar"
import { Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { KofiButton } from "react-kofi-button";

const vw = Math.min(480, Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));

const features = [
  {
    headline: "Get reunited with your lost property",
    detail: "Never lose your possessions again, get notified when your item is found so that you can recover it."
  },
  {
    headline: "Easy to use",
    detail: "An anonymous QR Code that you attach to your possessions for the finder to scan and alert you where your item is located."
  },
  {
    headline: "Safe and Secure",
    detail: "No personal information is revealed. The finder can simply send you a message letting you know where your item is."
  },
  {
    headline: "Completely Free",
    detail: "Free for personal use, non-profit and charitable organisations."
  }
]

const items = [
  {
    src: '/images/backpack.png',
    altText: 'Backpack',
    caption: 'Backpacks',
    width: `${vw-100}px`,
    key: 1,
  },
  {
    src: '/images/glasses-case.png',
    altText: 'Glasses Case',
    caption: 'Glasses Cases',
    key: 2,
  },
  {
    src: '/images/keys.png',
    altText: 'Keys',
    caption: 'Keys',
    key: 3,
  },
  {
    src: '/images/kindle.png',
    altText: 'Kindle',
    caption: 'Kindle',
    key: 4,
  },
  {
    src: '/images/luggage.png',
    altText: 'Luggage',
    caption: 'Luggage',
    key: 5,
  },
  {
    src: '/images/notebook.png',
    altText: 'Notebook',
    caption: 'Notebooks',
    key: 6,
  }
];

function Home() {
  const [featureIndex, setFeatureIndex] = useState(0);
  const [headline, setHeadline] = useState(features[0].headline);
  const [detail, setDetail] = useState(features[0].detail);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const cycleFeature = function (): void {
    const next = featureIndex < features.length - 1 ? featureIndex + 1 : 0;
    setFeatureIndex(next);
    setHeadline(features[next].headline);
    setDetail(features[next].detail);
  }

  useEffect(() => {
    setInterval(cycleFeature, 6000);
  }, [])

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img width={vw-24} style={{ border: "4px", borderColor: "#00b7eb", borderStyle: "solid", backgroundColor: "#B3E5FC"}} src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader="Tag anything . . ."
        />
      </CarouselItem>
    );
  });

  return (
    <div className="app-container">
      <div className="app-header">
        <NavBar page="home" signOut={undefined}/>
      </div>
      <div className="app-content">
        <Container className="my-3">
          <Row>
            <Col className="col-12 col-md-4 col-lg-5">
              <Card className="my-2" style={{ maxWidth: '456px' }}>
                <CardHeader style={{ backgroundColor: "#00b7eb" }}><h3>Features:</h3></CardHeader>
                <CardBody style={{ border: "4px", borderColor: "#00b7eb", borderStyle: "solid", backgroundColor: "#B3E5FC", height: '14rem' }}>
                  <CardTitle tag="h5">{headline}</CardTitle>
                  <CardText>{detail}</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 col-md-8 col-lg-7">
              <Carousel
                className="my-2" 
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
              <div style={{position: "relative", padding: "12px", left: `${vw-184}px`}}>
                <KofiButton title="If this App helps you get re-united with your belongs, please consider supporting us!" username="findertags" label="Support Us!" backgroundColor="#00b7eb"/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Home
